<template>
  <div>
    <e-charts
      ref="line"
      autoresize
      :options="line"
      theme="theme-color"
      auto-resize
      class="w-100"
      style="height: 270px"
    />
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import { $themeColors } from '@themeConfig'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  data() {
    return {
      line: {
        // Make gradient line here
        visualMap: [{
          show: true,
          type: 'continuous',
          min: 0,
          max: 400,
        }],
        grid: {
          width: '96%',
          left: '30px',
          top: '10px',
          show: false,
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: [{
          // boundaryGap: false,
          type: 'category',
          data: ['12/07/2022', '12/08/2022', '12/09/2022', '12/10/2022', '12/11/2022', '12/12/2022', '12/13/2022', '12/14/2022', '12/15/2022', '112/6/2022', '12/17/2022', '12/18/2022', '12/19/2022', '12/20/2022'],
        }],
        yAxis: {
          type: 'value',
          splitLine: { show: false },
        },
        series: {
          type: 'line',
          smooth: true,
          showSymbol: false,
          data: ['290', '200', '210', '190', '250', '220', '80', '90', '200', '150', '160', '100', '140', '100', '30'],
        },
      },
    }
  },
}
</script>

<style>

</style>
